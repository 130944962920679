import Link from "../controls/link";
import React from "react";

  /*Map marker with pulsing animation */
  export const MapMarker = (props: any) => {
    return (
      <>
        <Link
          to={
            "https://www.google.com/maps/dir/?api=1&" +
            "origin=My+Location&destination=" + props.lat + "," + props.lng + "&travelmode=driving"
          }
          internal={false}
          openNewTab={true}
        >
          <div className="pin" />
          <div className="pulse" />
        </Link>
      </>
    );
  };